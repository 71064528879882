import * as _ from 'lodash';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dataFilter'
})
export class MembersFilterPipe implements PipeTransform {

  transform(members: any, searchText: any): any {
    if (searchText == null) return members;

    return members.filter(function (member) {

      if (member.fullname != undefined) {
        var name_search = member.fullname.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
      }

      return name_search 
    })

  }
}
