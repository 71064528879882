import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
// import 'rxjs/add/operator/map';
import { JwtHelperService } from '@auth0/angular-jwt';
// import { ToastrService } from 'ngx-toastr';
import { appConfig } from 'src/app/app.config';


@Injectable()
export class AuthenticationService {
    constructor(private http: HttpClient) { }

    login(username: string, password: string) {
        return this.http.post<any>(appConfig.apiUrl + '/auth/signin', { email: username, password: password })
        .pipe(
            map(res => {
                // console.log(res);
                var user = res.data;
                if (user.verified == 0) {
                    // this.toastr.error(user.status)
                } else {
                    // login successful if there's a jwt token in the response
                    if (user.token != null) {
                        console.log(user)
                        console.log('Login Success')
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        // localStorage.setItem('currentUser', JSON.stringify(user));
                        // localStorage.setItem('userType', user.user.type);
                        localStorage.setItem('token', JSON.stringify(user.token));
                        localStorage.setItem('email', user.user.email);
                        localStorage.setItem('user_id', user.user.id);
                    }
                    return user;
                }


            }));
    }
    public isAuthenticated(): boolean {
        const token = localStorage.getItem('token');
        // Check whether the token is expired and return
        // true or false
        const helper = new JwtHelperService();
        // console.log(!helper.isTokenExpired(token));
        return !helper.isTokenExpired(token);
    }

    public isLogout() {
        sessionStorage.removeItem('token');
        sessionStorage.clear();
    }

    logout() {
        // remove user from local storage to log user out
        sessionStorage.removeItem('token');
    }
}