import * as _ from 'lodash';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dataFilter'
})
export class FilterPipe implements PipeTransform {

    transform(categories: any, searchText: any): any {
        if (searchText == null) return categories;

        return categories.filter(function (category) {

            if (category.fullname != undefined) {
                var fullname = category.fullname.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
            }
            if (category.city != undefined) {
                var city = category.city.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
            }

            return fullname || city
        })

    }
}
