import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import { Observable } from 'rxjs/Observable';
// import 'rxjs/add/operator/map';
import { map } from 'rxjs/operators';
import { appConfig } from 'src/app/app.config';

@Injectable()
export class MemberService {
    constructor(private http: HttpClient) { }

    getAllMembers() {
        return this.http.get<any>(appConfig.apiUrl + '/member/getAllMembers').pipe(map(res => {
            return res;
        }))
    }
    addMember(data) {
        return this.http.post<any>(appConfig.apiUrl + '/member/create', data).pipe(map(res => {
            return res;
        }))
    }

    updateMember(data) {
        return this.http.post<any>(appConfig.apiUrl + '/member/update', data).pipe(map(res => {
            return res;
        }))
    }

    deleteDocument(doc){
        return this.http.post<any>(appConfig.apiUrl + '/member/deleteDocument', doc).pipe(map(res => {
            return res;
        }))
    }

    // addLogoIcon(data){
    //     return this.http.post<any>(appConfig.apiUrl + '/shops/logo',data)
    //         .map(res => {
    //             return res;
    //         });
    //         //https://208.109.15.102:3020/shops/logo/1
    // }

}