import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { LoginComponent } from './views/login/login.component';
import { HeaderComponent } from './views/header/header.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { FileUploadModule } from 'ng2-file-upload';
import { FilterPipe } from './dataFilterPipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, LocationStrategy, HashLocationStrategy, DatePipe } from '@angular/common';

import { ToastrModule, ToastrService } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MemberService } from './views/services/member.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './helper';
import { AuthGuard } from './guards';
import { AuthenticationService } from './views/services/authentication.service';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction'; 
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { ViwerComponent } from './views/viwer/viwer.component';
import { MembersComponent } from './views/members/members.component';
// import { JwtInterceptor } from '@auth0/angular-jwt';
import { DataTableModule } from 'angular2-datatable';
import { MembersFilterPipe } from './views/members/dataFilterPipe';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    HeaderComponent,
    FilterPipe,
    ViwerComponent,
    MembersComponent,
    MembersFilterPipe,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgxDropzoneModule,
    FileUploadModule,
    NgxSpinnerModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    DataTableModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDyJD-Yfy3zixe1g84gbCV8SGeU544GN0E',
      // libraries: ['places', 'drawing', 'geometry']
    }),
    // AgmDirectionModule
  ],
  providers: [
    MemberService,
    AuthenticationService,
    AuthGuard,
    NgxSpinnerService,
    ToastrService,    
    DatePipe,
    {provide: LocationStrategy,useClass: HashLocationStrategy,
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },],
  bootstrap: [AppComponent]
})
export class AppModule { }
