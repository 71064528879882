import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
// import { ToastrService } from 'ngx-toastr';

import { MemberService } from '../services/member.service';
import { appConfig } from 'src/app/app.config';
import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Routes, RouterLinkActive, Router } from '@angular/router';
import { Local } from 'protractor/built/driverProviders';
import { AgmPolygon, PolyMouseEvent } from '@agm/core';
declare const google: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit {

  files: File[] = [];
  public uploader: FileUploader = new FileUploader({ isHTML5: true });

  members: any = [];
  member: any = {};
  newMember: any = {};
  url: any;
  title = 'My first AGM project';
  zoom: number = 15;

  // initial center position for the map
  lat: number = 51.673858;
  lng: number = 7.815982;

  origin = { lat: 24.799448, lng: 120.979021 };
  destination = { lat: 24.799524, lng: 120.975017 };
  filterQuery: any;

  @ViewChild('closebutton', { static: false }) closebutton;

  drawingManager: any;
  // pointList: any = [];
  // selectedArea: any = [];

  selectedLatLng: any = [];
  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService, private toastr: ToastrService,
    public route: Router,
    public memberService: MemberService) { }

  ngOnInit() {
    // this.initMap()
    this.getAllMembers();
    this.newMember.dob = "1993-01-01";
    this.newMember.reg_date = new Date();

    // map

  }

  getAllMembers() {
    this.spinner.show()
    this.memberService.getAllMembers()
      .subscribe(
        data => {
          if (data.data.verified == 1) {
            this.spinner.hide();
            this.members = data.data.info;
            this.members.forEach(element => {
              if (element.map_lat_longs != null)
                element.map_lat_longs = JSON.parse(element.map_lat_longs);
              if (element.profile_logo != null)
                // element.profile_logo  = location.origin + element.profile_logo;
                element.profile_logo = "https://dhol.app" + element.profile_logo;
              // element.image_url = "https://dhol.app" + element.image_url
            });
            // this.members = this.members.splice(0, 10);
            this.member = this.members[0];
            if (this.member.map_lat_longs != null && this.member.map_lat_longs.length > 0) {
              var map_lat_long = this.member.map_lat_longs[0];
              this.lat = map_lat_long.lat;
              this.lng = map_lat_long.lng;
              this.selectedLatLng = this.member.map_lat_longs;
            } else {
              this.selectedLatLng = [];
            }
          } else {
            // this.toastr.warning(data.data.status)
            console.log(data)
            this.spinner.hide()
          }
        },
        err => {
          console.log("error", err);
          this.spinner.hide()
        });
  }

  getMemberDetail(member) {
    this.member = member;
    if (this.member.map_lat_longs != null && this.member.map_lat_longs.length > 0) {
      var map_lat_long = this.member.map_lat_longs[0];
      this.lat = map_lat_long.lat;
      this.lng = map_lat_long.lng;
      this.selectedLatLng = this.member.map_lat_longs;


      // Cal
      // var sum = 0;
      // for (var i = 0, l = this.selectedLatLng.length - 1; i < l; i++) { sum += (this.selectedLatLng[i].lat * this.selectedLatLng[i + 1].lng - this.selectedLatLng[i + 1].lat * this.selectedLatLng[i].lng); }
      // // alert('The Area of Ploygon is:' + (sum / 2));
      // console.log(sum / 2)

      console.log(this.areaFromCoords(this.selectedLatLng))


    } else {
      this.selectedLatLng = [];
    }
  }

  areaFromCoords(coordArray) {

    var x = coordArray,
      a = 0;

    // Must have even number of elements
    if (x.length % 2) return;

    // Process pairs, increment by 2 and stop at length - 2
    for (var i = 0, iLen = x.length - 2; i < iLen; i += 2) {
      a += x[i].lat * x[i + 3].lng - x[i + 2].lat * x[i + 1].lng;
    }
    return Math.abs(a / 2);
  }


  change() {
    this.url = ""
  }

  getFilename(url) {
    let filename = url.split('/').reverse()[0];
    return filename;
  }

  getFileExt(url) {
    let fileext = url.split('.').pop();
    return fileext;
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => { // called once readAsDataURL is completed
        // this.url = event.target.result;
        var target = event.target["result"];
        this.url = target

      }
    }
  }

  onSelect(event) {
    console.log(event);
    this.files.push(...event.addedFiles);
  }

  onRemove(event) {
    // console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  addMember() {
    console.log(this.newMember);
    this.spinner.show()
    // this.spinnerService.show();
    this.memberService.addMember(this.newMember)
      .subscribe(
        data => {
          if (data.data.verified == 1) {
            // this.uploadProfileLogo(data.data.info.insertId);
            this.uploadDocuments(data.data.info.insertId);
            // this.getAllMembers();
            // this.closebutton.nativeElement.click();
          } else {
            // this.toastr.warning(data.data.status)
            this.spinner.hide()
            this.toastr.error(data.data.message);
            console.log(data)
          }
        },
        err => {
          this.spinner.hide()
          console.log("error");
        });
  }

  setDefaultPic(event) {
    event.target.src = "./assets/images/avatar.png";
  }

  async uploadProfileLogo(id) {
    for (let i = 0; i < this.uploader.queue.length; i++) {
      let fileItem = this.uploader.queue[i]._file;
      if (fileItem.size > 10000000) {
        alert("Each File should be less than 10 MB of size.");
        return;
      }
    }
    for (let j = 0; j < this.uploader.queue.length; j++) {
      let data = new FormData();
      let fileItem = this.uploader.queue[j]._file
      data.append('file', fileItem);
      await this.uploadProfile(id, data);
    }
  }

  async uploadProfile(id, data: FormData) {
    return new Promise((resolve, reject) => {
      // your upload code      
      this.http.post(appConfig.apiUrl + '/member/profile_logo/' + id, data, { reportProgress: true, observe: 'events' })
        .subscribe(event => {
          if (event.type === HttpEventType.UploadProgress) {

          } else if (event instanceof HttpResponse) {
            if (event.status == 200) {
              resolve();
            }
            console.log("else event", event)
          }
        });
    });
  }

  async uploadDocuments(id) {
    const promisesArray: any[] = [];
    console.log("Files:- ", this.files.length)
    await promisesArray.push(this.uploadProfileLogo(id));
    for (let j = 0; j < this.files.length; j++) {
      let data = new FormData();
      let fileItem = this.files[j]
      data.append('file', fileItem);
      console.log("file:- ", fileItem)
      await promisesArray.push(this.uploadDocumentsFile(id, data));
    }

    await Promise.all(promisesArray)
      .then((res) => {
        setTimeout(() => {
          console.log("All uploads done");
          this.spinner.hide();
          this.closebutton.nativeElement.click();
          this.getAllMembers();
        }, 3000);
      }, (firstErr) => {
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        console.error("Error uploading file.", firstErr);
      });
  }

  async uploadDocumentsFile(id, data: FormData) {
    return new Promise((resolve, reject) => {
      // your upload code      
      this.http.post(appConfig.apiUrl + '/member/documents_upload/' + id, data, { reportProgress: true, observe: 'events' })
        .subscribe(event => {
          if (event.type === HttpEventType.UploadProgress) {


          } else if (event instanceof HttpResponse) {
            if (event.status == 200) {
              resolve();
            }
          }
        });
    });

  }

  clear() {
    this.newMember = {};
    this.newMember.dob = "1993-01-01";
    this.newMember.reg_date = new Date();
    this.files = [];
    this.url = ""
  }

  openViwer(doc) {
    this.route.navigate(['/viwer'], { queryParams: { data: doc.image_url } });
    // this.route.navigate([]).then(result => {  window.open('http://localhost:4200/viwer', '_blank'); });
  }

  deleteDoc(doc) {
    if (confirm("Are you sure to delete")) {
      console.log("Implement delete functionality here");
      this.spinner.show()
      // this.spinnerService.show();
      this.memberService.deleteDocument(doc)
        .subscribe(
          data => {
            this.spinner.hide()
            if (data.data.verified == 1) {
              this.getAllMembers();
            } else {
              this.toastr.error(data.data.message);
              console.log(data)
            }
          },
          err => {
            this.spinner.hide()
            console.log("error");
          });
    }
  }


  // onMapReady(map) {
  //   this.initDrawingManager(map);
  // }


  // For draw polygone
  // initDrawingManager = (map: any) => {
  //   // console.log(this.mylocations)
  //   const self = this;
  //   const options = {
  //     drawingControl: true,
  //     drawingControlOptions: {
  //       drawingModes: ['polygon'],
  //     },
  //     polygonOptions: {
  //       draggable: false,
  //       editable: false,
  //     },
  //     drawingMode: google.maps.drawing.OverlayType.POLYGON,
  //   };
  //   this.drawingManager = new google.maps.drawing.DrawingManager(options);
  //   this.drawingManager.setMap(map);
  //   google.maps.event.addListener(
  //     this.drawingManager,
  //     'overlaycomplete',
  //     (event) => {
  //       if (event.type === google.maps.drawing.OverlayType.POLYGON) {
  //         const paths = event.overlay.getPaths();
  //         for (let p = 0; p < paths.getLength(); p++) {
  //           google.maps.event.addListener(
  //             paths.getAt(p),
  //             'set_at',
  //             () => {
  //               if (!event.overlay.drag) {
  //                 self.updatePointList(event.overlay.getPath());
  //               }
  //             }
  //           );
  //           google.maps.event.addListener(
  //             paths.getAt(p),
  //             'insert_at',
  //             () => {
  //               self.updatePointList(event.overlay.getPath());
  //             }
  //           );
  //           google.maps.event.addListener(
  //             paths.getAt(p),
  //             'remove_at',
  //             () => {
  //               self.updatePointList(event.overlay.getPath());
  //             }
  //           );
  //         }
  //         self.updatePointList(event.overlay.getPath());
  //       }
  //       if (event.type !== google.maps.drawing.OverlayType.MARKER) {
  //         // Switch back to non-drawing mode after drawing a shape.
  //         self.drawingManager.setDrawingMode(null);
  //         // To hide:
  //         self.drawingManager.setOptions({
  //           drawingControl: false,
  //         });
  //       }
  //     }
  //   );
  // }

  // pointList:any =[];
  // selectedArea:any=[];

  // updatePointList(path) {
  //   console.log(path)
  //   this.pointList = [];
  //   const len = path.getLength();
  //   for (let i = 0; i < len; i++) {
  //     this.pointList.push(
  //       path.getAt(i).toJSON()
  //     );
  //   }
  //   console.log(JSON.stringify(this.pointList))
  //   this.selectedArea = google.maps.geometry.spherical.computeArea(
  //     path
  //   );

  //   console.log( this.selectedArea)
  // }

  onPolyClick($event, polygon: AgmPolygon) {
    // console.log($event.getPath());
    // console.log(polygon.getPath());
    // var measure = {
    //   mvcLine: new google.maps.MVCArray(),
    //   mvcPolygon: new google.maps.MVCArray(),
    //   mvcMarkers: new google.maps.MVCArray(),
    //   line: null,
    //   polygon: null
    // };
    console.log(google.maps.geometry.spherical.computeArea(polygon));

    // console.log(this.CalculatePolygonArea(this.selectedLatLng))
    // convertAreaToHect

  }

// CalculatePolygonArea(selectedLatLng)
// {
//   var area = 0;

//   if (selectedLatLng.length > 2) {
//     console.log(selectedLatLng.length)
//     for (var i = 0; i < selectedLatLng.length - 1; i++) {
//       var p1 = selectedLatLng[i];
//       var p2 = selectedLatLng[i + 1];
//       area += (this.ConvertToRadian(p2.lng) - this.ConvertToRadian(p1.lng)) * (2 + Math.sin(this.ConvertToRadian(p2.lat)) + Math.sin(this.ConvertToRadian(p1.lat)));
      

//     // area += ConvertToRadian(p2.Longitude - p1.Longitude) * (2 + Math.Sin(ConvertToRadian(p1.Latitude)) + Math.Sin(ConvertToRadian(p2.Latitude)));

      
//     }

//     area = area * 6378137 * 6378137 / 2;
//   }

//   return Math.abs(area) ; //ha devide by 10000 
// }

// ConvertToRadian(input)
// {
//   return input * Math.PI / 180;
// }



}

