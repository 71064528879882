import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-viwer',
  templateUrl: './viwer.component.html',
  styleUrls: ['./viwer.component.css']
})
export class ViwerComponent implements OnInit {

  url;
  constructor(public route: ActivatedRoute, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.route
      .queryParams
      .subscribe(params => {
        this.url = params['data'];
        // this.url = "https://dhol.app" + this.url + '&embedded=true';
        this.url = "https://dhol.app" + this.url ;
        console.log(this.url)
      });
  }
  sanitizeImageUrl(imageUrl: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
  }

}
