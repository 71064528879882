import { DatePipe } from '@angular/common';
import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { appConfig } from 'src/app/app.config';
import { MemberService } from '../services/member.service';

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.css']
})
export class MembersComponent implements OnInit {

  files: File[] = [];
  public uploader: FileUploader = new FileUploader({ isHTML5: true });
  url: any;

  members: any = [];
  member: any = {};
  newMember: any = {};
  filterQuery: any;

  @ViewChild('closebutton', { static: false }) closebutton;

  constructor(private spinner: NgxSpinnerService, private toastr: ToastrService,  private http: HttpClient,
    public memberService: MemberService, public datepipe: DatePipe) { }

  ngOnInit() {
    this.getAllMembers();
  }

  getAllMembers() {
    this.spinner.show()
    this.memberService.getAllMembers()
      .subscribe(
        data => {
          if (data.data.verified == 1) {
            this.spinner.hide();
            this.members = data.data.info;
            this.members.forEach(element => {
              if (element.map_lat_longs != null)
                element.map_lat_longs = JSON.parse(element.map_lat_longs);
              // if (element.profile_logo != null)
              //   element.profile_logo = "https://dhol.app" + element.profile_logo;
            });
          } else {
            // this.toastr.warning(data.data.status)
            console.log(data)
            this.spinner.hide()
          }
        },
        err => {
          console.log("error", err);
          this.spinner.hide()
        });
  }

  onSelect(event) {
    console.log(event);
    this.files.push(...event.addedFiles);
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => { // called once readAsDataURL is completed
        // this.url = event.target.result;
        var target = event.target["result"];
        this.url = target

      }
    }
  }

  onRemove(event) {
    // console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  setDefaultPic(event) {
    event.target.src = "./assets/images/avatar.png";
  }

  openEditModel(member) {
    console.log(member)
    this.newMember = member;
    this.newMember.dob = this.datepipe.transform(this.newMember.dob, 'yyyy-MM-dd');
    this.newMember.reg_date = this.datepipe.transform(this.newMember.reg_date, 'yyyy-MM-dd');    
    this.url = 'https://dhol.app' + member.profile_logo;
  }

  change() {
    this.url = ""
  }

  clear() {
    this.newMember = {};
    this.newMember.dob = "1993-01-01";
    // this.newMember.reg_date = new Date();
    this.files = [];
    this.url = ""
  }

  async uploadProfileLogo(id) {
    for (let i = 0; i < this.uploader.queue.length; i++) {
      let fileItem = this.uploader.queue[i]._file;
      if (fileItem.size > 10000000) {
        alert("Each File should be less than 10 MB of size.");
        return;
      }
    }
    for (let j = 0; j < this.uploader.queue.length; j++) {
      let data = new FormData();
      let fileItem = this.uploader.queue[j]._file
      data.append('file', fileItem);
      await this.uploadProfile(id, data);
    }
  }

  async uploadProfile(id, data: FormData) {
    return new Promise((resolve, reject) => {
      // your upload code      
      this.http.post(appConfig.apiUrl + '/member/profile_logo/' + id, data, { reportProgress: true, observe: 'events' })
        .subscribe(event => {
          if (event.type === HttpEventType.UploadProgress) {

          } else if (event instanceof HttpResponse) {
            if (event.status == 200) {
              resolve();
            }
            console.log("else event", event)
          }
        });
    });
  }

  async uploadDocumentsFile(id, data: FormData) {
    return new Promise((resolve, reject) => {
      // your upload code      
      this.http.post(appConfig.apiUrl + '/member/documents_upload/' + id, data, { reportProgress: true, observe: 'events' })
        .subscribe(event => {
          if (event.type === HttpEventType.UploadProgress) {


          } else if (event instanceof HttpResponse) {
            if (event.status == 200) {
              resolve();
            }
          }
        });
    });

  }

  async uploadDocuments(id) {
    const promisesArray: any[] = [];
    console.log("Files:- ", this.files.length)
    await promisesArray.push(this.uploadProfileLogo(id));
    for (let j = 0; j < this.files.length; j++) {
      let data = new FormData();
      let fileItem = this.files[j]
      data.append('file', fileItem);
      console.log("file:- ", fileItem)
      await promisesArray.push(this.uploadDocumentsFile(id, data));
    }

    await Promise.all(promisesArray)
      .then((res) => {
        setTimeout(() => {
          console.log("All uploads done");
          this.spinner.hide();
          this.closebutton.nativeElement.click();
          this.getAllMembers();
        }, 3000);
      }, (firstErr) => {
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        console.error("Error uploading file.", firstErr);
      });
  }

  updateMember() {
    this.spinner.show()
    // this.spinnerService.show();
    this.memberService.updateMember(this.newMember)
      .subscribe(
        data => {
          if (data.data.verified == 1) {
            this.uploadDocuments(this.newMember.id);
          } else {
            // this.toastr.warning(data.data.status)
            this.spinner.hide()
            this.toastr.error(data.data.message);
            console.log(data)
          }
        },
        err => {
          this.spinner.hide()
          console.log("error");
        });

  }

}
