import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  user:any={};
  constructor(private authenticationService: AuthenticationService,  private _router: Router,private spinner: NgxSpinnerService) { }

  ngOnInit() {
  }

  login() {

    if (this.user.email == null && this.user.password == null) {
      return false;
    } else {
      this.authenticationService.login(this.user.email, this.user.password)
        .subscribe(
          data => {
            this._router.navigate(['dashboard']);
          },
          error => {
            console.log(error)
          }
        );
    }

  }

}
