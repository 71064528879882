import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { LoginComponent } from './views/login/login.component';
import { AuthGuard } from './guards';
import { ViwerComponent } from './views/viwer/viwer.component';
import { MembersComponent } from './views/members/members.component';


const routes: Routes = [

  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  
  {
    path:"dashboard",
    component:DashboardComponent,
    canActivate:[AuthGuard],
  },
  {
    path:"viwer",
    component:ViwerComponent,
    canActivate:[AuthGuard],
  },
  {
    path:"members",
    component:MembersComponent,
    canActivate:[AuthGuard],
  },
  {
    path:"login",
    component:LoginComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
